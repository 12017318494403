/* eslint-disable max-len */
import "./Location.scss";
import "swiper/scss";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, IBreadcrumb } from "components/breadcrumbs/Breadcrumbs";
import { ExpandableText } from "components/expandable-text/ExpandableText";
import { TwoColsLayout } from "components/layout/two-cols-layout/TwoColsLayout";
import { Services } from "components/services/Services";
import { Avatar } from "elements/avatar/Avatar";
import { Button } from "elements/button/Button";
import { IconButton } from "elements/icon-button/IconButton";
import { ImageElement } from "elements/image/Image";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { COLORS } from "models/colors";
import { VARIANTS } from "models/variants";
import { bemElement } from "utils/bem-class-names";
import { handleGoBack } from "utils/handleGoBack";
import { getTime, getZonedDate } from "utils/date-time";
import { useGeolocation } from "providers/geolocation-provider";
import DateAndTime, { IModalError } from "components/date-and-time/DateAndTime";
import FormattedTextModal from "components/modals/formatted-text-modal/FormattedTextModal";
import { TLocation } from "types";
import { useCart } from "providers/cart-provider";
import {
  LocationPageLocationFragment,
  MenuSectionsQuery,
  useAddLocationToFavoriteMutation,
  useLocationQuery,
  useRemoveLocationFromFavoriteMutation
} from "./Location.generated";
import { useUser } from "../../providers/user";
import { HEADER_TYPE, useHeader } from "providers/header-provider";
import useResponsive from "hooks/useResponsive";
import { IconTitle } from "components/header/sections/icon-title/icon-title";
import { useHeaderTransparent } from "components/header/Header";
import { getRefundAndCancellationHtml } from "../../utils/get-refund-and-cancellation-html";
import { useAxios } from "../../providers/axios";
import { TitleSubtitle } from "components/header/sections/title-subtitle/title-subtitle";
import { joinClassNames } from "utils/join-class-names";
import SlidingModal from "components/modals/sliding-modal/SlidingModal";
import { LocationGoogleMap } from "./sections/LocationGoogleMap/LocationGoogleMap";
import { LocationMainData } from "./sections/LocationMainData/LocationMainData";
import {
  LocationInfoLink,
  generateGoogleMapsLink
} from "./sections/LocationInfoLink/LocationInfoLink";
import {
  IWeekDay,
  LocationWorkingHours
} from "./sections/LocationWorkingHours/LocationWorkingHours";
import { ListItem } from "components/list-item/ListItem";
import { MenuSection } from "types.generated";
import Modal from "components/modals/modal/Modal";
import {
  amenitiesWithAlternativeIcons,
  schedulingBufferMinutes
} from "../../utils/constants";
import { Amenities } from "components/amenities/Amenities";
import { getPhone } from "../../utils/getPhone";
import { getRawPhoneNumber } from "../../utils/getRawPhoneNumber";
import { gql, useQuery } from "@apollo/client";

const baseClassName = "location-page";
const bem = bemElement(baseClassName);

// TODO: refactor this according to real data
const getWeekDays = (location: LocationPageLocationFragment): IWeekDay[] => {
  const weekDayNames = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday"
  ];
  const weekDayDates: Array<Array<string | null>> = [];

  weekDayDates.push([
    location.sunday_hours_open || null,
    location.sunday_hours_close || null
  ]);
  weekDayDates.push([
    location.monday_hours_open || null,
    location.monday_hours_close || null
  ]);
  weekDayDates.push([
    location.tuesday_hours_open || null,
    location.tuesday_hours_close || null
  ]);
  weekDayDates.push([
    location.wednesday_hours_open || null,
    location.wednesday_hours_close || null
  ]);
  weekDayDates.push([
    location.thursday_hours_open || null,
    location.thursday_hours_close || null
  ]);
  weekDayDates.push([
    location.friday_hours_open || null,
    location.friday_hours_close || null
  ]);
  weekDayDates.push([
    location.saturday_hours_open || null,
    location.saturday_hours_close || null
  ]);

  const currentWeekDayNumber = getZonedDate(new Date().toISOString()).getDay();

  return weekDayDates.map(([openDate, closeDate], index: number) => {
    return {
      startTime: openDate ? getTime(openDate, location.timezone) : null,
      endTime: closeDate ? getTime(closeDate, location.timezone) : null,
      startDate: openDate
        ? new Date(
            new Date().setHours(
              getZonedDate(openDate, location.timezone).getHours()
            )
          )
        : null,
      endDate: closeDate
        ? new Date(
            new Date().setHours(
              getZonedDate(closeDate, location.timezone).getHours()
            )
          )
        : null,
      weekDayName: weekDayNames[index],
      today: currentWeekDayNumber === index
    };
  });
};

const translations = {
  rightColumnName: "Menu",
  weekDayNames: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    closed: "Closed"
  }
};

const GET_MENU_SECTIONS = gql`
  query MenuSections(
    $where: MenuSectionWhereInput
    $orderBy: [MenuSectionOrderByWithRelationInput!]
    $menuSectionServicesOrderBy2: [MenuSectionServiceOrderByWithRelationInput!]
  ) {
    menuSections(where: $where, orderBy: $orderBy) {
      id
      name
      section_position
      menu_section_services(orderBy: $menuSectionServicesOrderBy2) {
        id
        section_position
        service {
          id
          name
          price
          category
          description
          duration
          special_conditions
          photo_1_url
          photo_2_url
          photo_3_url
          photo_4_url
          photo_5_url
          add_ons {
            id
            add_on {
              id
              name
              description
              duration
              price
              special_conditions
              photo_1_url
              photo_2_url
              photo_3_url
              photo_4_url
              photo_5_url
            }
            core_service {
              id
              choice_is_required
              description
              duration
              name
              price
              photo_1_url
              photo_2_url
              photo_3_url
              photo_4_url
              photo_5_url
              scheduling_priority
              special_conditions
            }
          }
        }
      }
    }
  }
`;

export const Location = (): JSX.Element | null => {
  const params = useParams();
  const navigate = useNavigate();
  const { api } = useAxios();
  const { user, isLoggedIn } = useUser();
  const { setCurrentLocation, activePersonsNumber } = useCart();
  const isHeaderTransparent = useHeaderTransparent();
  const { setData } = useHeader();
  const { calculateDistance } = useGeolocation();
  const [weekDays, setWeekDays] = useState<IWeekDay[]>([]);
  const [menuRoutes, setMenuRoutes] = useState<IBreadcrumb[]>([]);
  const [distance, setDistance] = useState<number | null>(null);
  const [isBooking, setIsBooking] = useState<boolean>(false);
  const [showTextModal, setShowTextModal] = useState<boolean>(false);
  const [textModalData, setTextModalData] = useState<{
    title: string;
    text: string;
  }>();
  const [modalError, setModalError] = useState<IModalError | null>(null);
  const { isDesktop, isXS } = useResponsive();
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [isFavorited, setIsFavorited] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [artists, setArtists] = useState<any>();
  const {
    data: locationData,
    loading: locationLoading,
    refetch
  } = useLocationQuery({
    variables: {
      where: {
        slug: params.locationSlug
      }
    },
    skip: !params.locationSlug
  });
  const { data: menu } = useQuery<MenuSectionsQuery>(GET_MENU_SECTIONS, {
    variables: {
      where: {
        menu_id: {
          equals: locationData?.location?.menu?.id
        }
      },
      orderBy: [
        {
          section_position: "asc"
        }
      ],
      menuSectionServicesOrderBy2: [
        {
          section_position: "asc"
        }
      ]
    }
  });

  const location = locationData?.location;
  const services = menu?.menuSections as MenuSection[];

  const [addLocationToFavorite] = useAddLocationToFavoriteMutation({
    variables: {
      data: {
        location: {
          connect: {
            id: location?.id
          }
        },
        user: {
          connect: {
            id: user?.id
          }
        }
      }
    }
  });
  const [removeLocationFromFavorite] = useRemoveLocationFromFavoriteMutation();

  const onFavoriteButtonClick = useCallback(async () => {
    setIsLoading(true);
    if (user?.id) {
      if (location?.favorited_by_users?.length === 0) {
        setIsFavorited(false);
        await addLocationToFavorite();
      } else if (location?.favorited_by_users[0]?.id) {
        setIsFavorited(true);
        await removeLocationFromFavorite({
          variables: {
            where: {
              id: location.favorited_by_users[0].id
            }
          }
        });
      }
      setIsLoading(false);
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addLocationToFavorite,
    location?.favorited_by_users,
    refetch,
    removeLocationFromFavorite,
    user?.id
  ]);

  const getDateAndTimeComponent = (_className: string = "") => {
    return (
      <DateAndTime
        onCurrentRoutes={(routes?: IBreadcrumb[]) =>
          setMenuRoutes(routes ? routes : [])
        }
        className={_className}
        onClose={() => setIsBooking(false)}
        setModalError={setModalError}
      />
    );
  };

  const BottomControls = () => {
    return (
      <div className="flex justify-center px-4 py-2 bg-background border-t border-t-surface-variant">
        <Button
          color={COLORS.SUCCESS}
          variant={VARIANTS.FILLED}
          text="Ok got it"
          onClick={() => {
            setIsBooking(false);
            setModalError(null);
            if (modalError?.type === "Company not verified") {
              navigate("/");
            }
          }}
        />
      </div>
    );
  };

  const setPageHeader = (): void => {
    setData({
      type: HEADER_TYPE.TYPE_1,
      leftSection: isDesktop ? null : (
        <IconButton
          color={COLORS.SECONDARY_CONTAINER}
          variant={
            isXS && isHeaderTransparent ? VARIANTS.FILLED : VARIANTS.DEFAULT
          }
          iconName="arrow_left"
          onClick={() => handleGoBack(navigate)}
        />
      ),
      rightSection: isDesktop ? null : isLoggedIn ? (
        <IconButton
          color={COLORS.SECONDARY_CONTAINER}
          variant={
            isXS && isHeaderTransparent ? VARIANTS.FILLED : VARIANTS.DEFAULT
          }
          iconName={isFavorited ? "heart" : "filled_heart"}
          disabled={isLoading}
          onClick={onFavoriteButtonClick}
          cyId="favorite-button-mobile"
        />
      ) : null,
      centerSection: isDesktop ? null : isXS && isHeaderTransparent ? (
        <></>
      ) : (
        <IconTitle
          title={location?.name || ""}
          src={location?.company?.logo_url || ""}
        />
      )
    });
  };

  const setDateAndTimeHeader = (): void => {
    setData({
      type: HEADER_TYPE.DEFAULT,
      leftSection: isDesktop ? null : (
        <IconButton
          color={COLORS.SECONDARY}
          variant={VARIANTS.DEFAULT}
          iconName="arrow_left"
          onClick={() => setIsBooking(false)}
        />
      ),
      rightSection: isDesktop ? null : <></>,
      centerSection: isDesktop ? null : (
        <TitleSubtitle
          title={"Date and time"}
          subtitle={
            <span>
              {location?.name} • {activePersonsNumber}{" "}
              {activePersonsNumber > 1 ? "people" : "person"}
            </span>
          }
        />
      )
    });
  };

  useEffect(() => {
    setIsFavorited(location?.favorited_by_users.length === 0);
  }, [location]);

  useEffect(() => {
    if (isBooking) {
      setDateAndTimeHeader();
    } else {
      setPageHeader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isDesktop, isXS, isHeaderTransparent, isBooking, isFavorited]);

  useEffect(() => {
    if (location?.id) {
      (async () => {
        const { data } = await api.post("/v1/possible-appointments", {
          locations: [location.id],
          startTime:
            new Date().getTime() +
            schedulingBufferMinutes(!!user?.impersonate_name) * 60 * 1000,
          limit: 6
        });
        if (data?.length > 0) {
          (async () => {
            const result = await Promise.all(
              data[0].artists?.map(async (artist: any) => {
                const { data } = await api.post("/v1/possible-appointments", {
                  locations: [location.id],
                  startTime:
                    new Date().getTime() +
                    schedulingBufferMinutes(!!user?.impersonate_name) *
                      60 *
                      1000,
                  artist: artist.id,
                  limit: 1
                });
                const possibleAppointments = data.find(
                  (item: any) => item.appointments.length > 0
                );
                if (possibleAppointments) {
                  return {
                    ...artist,
                    possibleAppointment: possibleAppointments.appointments[0]
                  };
                } else {
                  return { ...artist, possibleAppointment: null };
                }
              })
            );
            setArtists(result);
          })();
        }
      })();
    }
  }, [location?.id, api, user?.impersonate_name]);

  useEffect(() => {
    if (location) {
      setCurrentLocation(location as unknown as TLocation);

      if (location.address_latitude && location.address_longitude) {
        calculateDistance(
          location.address_latitude,
          location.address_longitude
        ).then((distance: number | null) => {
          setDistance(distance);
        });
      }
      setWeekDays(getWeekDays(location));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateDistance, location, onFavoriteButtonClick]);

  useEffect(() => {
    // Check if location company is verified and if not redirect to homepage
    if (location && location.company.is_verified === false) {
      setModalError({
        type: "Company not verified",
        text: "This company is not verified yet. Please try again later."
      });
    }
  }, [location, location?.company.is_verified]);

  if (!location || !services || locationLoading) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Pointment | {location.name}</title>
        <meta property="og:title" content={`Pointment | ${location.name}`} />
      </Helmet>
      {!isDesktop && isBooking ? (
        <div> {getDateAndTimeComponent()} </div>
      ) : (
        <TwoColsLayout className={baseClassName}>
          <div className={bem("main")}>
            <div className={bem("breadcrumbs-wrapper")}>
              <Breadcrumbs breadcrumbs={[{ title: location.name, url: "" }]} />
            </div>
            <div className={bem("head")}>
              <div className={bem("head-container")}>
                <div className={bem("company-logo-container")}>
                  <Avatar
                    className={bem("company-logo")}
                    src={location?.company?.logo_url || ""}
                    alt={location?.company?.name}
                    shape="square"
                    size="lg"
                  />
                </div>
                {isLoggedIn && (
                  <div className={bem("favorite-button-container")}>
                    <IconButton
                      className={bem("favorite-button")}
                      color={COLORS.PRIMARY}
                      iconName={isFavorited ? "heart" : "filled_heart"}
                      onClick={onFavoriteButtonClick}
                      disabled={isLoading}
                      cyId={"favorite-button-desktop"}
                    />
                  </div>
                )}
                <ImageElement
                  className={bem("cover-photo")}
                  src={location.cover_photo_url || ""}
                  alt={location.name}
                  aspectRatio="16:9"
                />
              </div>
            </div>
            <div className={bem("main-description")}>
              <div
                className={joinClassNames(bem("main-description-data"), "flex")}
              >
                <div className="flex-2">
                  <LocationMainData
                    location={location}
                    distance={distance}
                    locationWorkingHoursComponent={
                      <LocationWorkingHours
                        location={location}
                        weekDays={weekDays}
                        mobileVersion
                      />
                    }
                  />
                </div>
                <div>
                  <IconButton
                    iconName="info"
                    color={COLORS.TERTIARY_CONTAINER}
                    variant={VARIANTS.FILLED}
                    onClick={() => setInfoModalOpen(true)}
                  />
                </div>
              </div>
              <div className={bem("main-description-amenities")}>
                {location?.amenities_enums?.map(
                  (amenity: string, index: number) => (
                    <div key={index} className="flex flex-wrap">
                      <SvgIcon
                        name={
                          amenity === "Free_Wifi"
                            ? "amenities_free_wi_fi"
                            : amenitiesWithAlternativeIcons.includes(amenity)
                            ? "amenities_alternate_version"
                            : `amenities_${amenity?.toLocaleLowerCase()}`
                        }
                      />
                    </div>
                  )
                )}
              </div>
            </div>
            <div className={bem("main-content")}>
              <div className={bem("main-content-left")}>
                <div className={bem("main-content-left-container")}>
                  <LocationMainData location={location} distance={distance} />
                  <LocationWorkingHours
                    location={location}
                    weekDays={weekDays}
                  />
                  {!!location?.description && (
                    <div className={bem("category")}>
                      <ExpandableText
                        text={location.description}
                        limit={4}
                        method="lines"
                      />
                    </div>
                  )}
                  {/* POI-1180 - Temporarily hiding this code with a comment. We will need it again very soon. */}
                  {/* {artists && artists?.length > 0 && (
                    <div className={bem("category")}>
                      <Slider
                        title="Artists"
                        navigationConfig={{ position: "top-right" }}
                      >
                        {artists.map((artist: any) => {
                          if (user?.impersonate_name) {
                            return (
                              <ArtistPreview
                                value={artist}
                                availableTime={
                                  artist?.possibleAppointment?.start_time
                                }
                                key={artist.id}
                              />
                            );
                          }
                          return (
                            <Link to={`/artists/${artist.id}`}>
                              <ArtistPreview
                                value={artist}
                                availableTime={
                                  artist?.possibleAppointment?.start_time
                                }
                                key={artist.id}
                              />
                            </Link>
                          );
                        })}
                      </Slider>
                    </div>
                  )} */}
                  {!!location?.amenities_enums?.length && (
                    <Amenities amenitiesData={location?.amenities_enums} />
                  )}
                </div>
              </div>
              <div className={bem("main-content-right")}>
                <div className={bem("main-content-right-container")}>
                  <LocationGoogleMap
                    className={bem("location-map")}
                    location={location}
                  />
                  <div className={bem("location-info")}>
                    {location?.address_display && (
                      <LocationInfoLink
                        href={generateGoogleMapsLink(location.address_display)}
                        name="Get directions"
                        iconName="temp_arrow_outward"
                      />
                    )}
                    {location?.phone_number && (
                      <LocationInfoLink
                        href={`tel:${getRawPhoneNumber(location.phone_number)}`}
                        name={getPhone(location.phone_number)}
                        iconName="temp_call"
                      />
                    )}
                    <LocationInfoLink
                      href={`/company/${location.company.slug}`}
                      target="_self"
                      name="Company page"
                      iconName="temp_website"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${bem(
                "category"
              )} border-transparent hidden lg:block`}
            >
              <h4>Additional</h4>
              <div className={bem("category-additional")}>
                <Button
                  className="!bg-surface"
                  color={COLORS.PRIMARY}
                  text="Refund & Cancellation Policy"
                  iconRightName="arrow_right"
                  size="sm"
                  variant={VARIANTS.DEFAULT}
                  onClick={() => {
                    setShowTextModal(true);
                    setTextModalData({
                      title: "Refund & Cancellation",
                      text: getRefundAndCancellationHtml(
                        location?.company.late_cancellation_hours,
                        location?.company.late_cancellation_charge,
                        location?.company.no_show_charge,
                        location?.company.payment_type
                      )
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className={bem("menu")}>
            <div className={bem("menu-container")}>
              <div className={bem("menu-header")}>
                <Breadcrumbs
                  rootCrumbName={translations.rightColumnName}
                  breadcrumbs={menuRoutes}
                />
              </div>
              {isBooking ? (
                getDateAndTimeComponent(bem("menu-content"))
              ) : (
                <Services
                  onSubmit={() => setIsBooking(true)}
                  onCurrentRoute={(route?: IBreadcrumb) =>
                    setMenuRoutes(route ? [route] : [])
                  }
                  className={bem("menu-content")}
                  items={services}
                />
              )}
            </div>
          </div>
        </TwoColsLayout>
      )}

      <SlidingModal
        show={infoModalOpen}
        onHide={() => setInfoModalOpen(false)}
        transparentHeader
        enableScroll
      >
        <>
          <LocationGoogleMap
            className={bem("location-map")}
            location={location}
          />
          <div className="py-16px">
            <div className="flex px-16px">
              <div className="flex-2">
                <LocationMainData
                  location={location}
                  distance={distance}
                  showTime={false}
                />
              </div>
              <div>
                <Avatar
                  className={bem("company-logo")}
                  src={location?.company?.logo_url || ""}
                  alt={location?.company?.name}
                  shape="square"
                  size="md"
                />
              </div>
            </div>
            <div className={joinClassNames(bem("location-info"), "px-16px")}>
              {location?.address_display && (
                <LocationInfoLink
                  href={generateGoogleMapsLink(location.address_display)}
                  name="Get directions"
                  iconName="temp_arrow_outward"
                />
              )}
              {location?.phone_number && (
                <LocationInfoLink
                  href={`tel:${getRawPhoneNumber(location.phone_number)}`}
                  name={getPhone(location.phone_number)}
                  iconName="temp_call"
                />
              )}
              {location?.company?.website_url && (
                <LocationInfoLink
                  href={`/company/${location.company.slug}`}
                  name="Company page"
                  iconName="temp_website"
                />
              )}
            </div>
            <div className="px-16px">
              <LocationWorkingHours location={location} weekDays={weekDays} />
            </div>
            {!!location?.description && (
              <div className={joinClassNames(bem("category"), "mx-16px")}>
                <ExpandableText
                  text={location.description}
                  limit={4}
                  method="lines"
                />
              </div>
            )}
            {/* POI-1180 - Temporarily hiding this code with a comment. We will need it again very soon. */}
            {/* {artists && artists?.length > 0 && (
              <div>
                <h4 className={bem("artists-title")}>Artists</h4>
                <div
                  className={joinClassNames(
                    bem("category"),
                    bem("artists"),
                    "hide-scroll-bar"
                  )}
                >
                  {artists.map((artist: any) => (
                    <Link to={`/artists/${artist.id}`}>
                      <ArtistPreview
                        value={artist}
                        availableTime={artist?.possibleAppointment?.start_time}
                        key={artist.id}
                      />
                    </Link>
                  ))}
                </div>
                <hr className="mx-16px border-surface-variant" />
              </div>
            )} */}
            {location?.amenities_enums?.length > 0 && (
              <Amenities
                amenitiesData={location?.amenities_enums}
                className="px-4"
              />
            )}
            <div className={joinClassNames(bem("additional"), "px-16px")}>
              <h4>Additional</h4>
              <ListItem
                className={bem("additional-list-item")}
                onClick={() => {
                  setShowTextModal(true);
                  setTextModalData({
                    title: "Refund & Cancellation",
                    text: getRefundAndCancellationHtml(
                      location?.company.late_cancellation_hours,
                      location?.company.late_cancellation_charge,
                      location?.company.no_show_charge,
                      location?.company.payment_type
                    )
                  });
                }}
              >
                <div>
                  <span>Refund & Cancellation Policy</span>
                </div>
              </ListItem>
            </div>
          </div>
        </>
      </SlidingModal>

      <FormattedTextModal
        show={showTextModal}
        onHide={() => setShowTextModal(false)}
        title={textModalData?.title}
        text={textModalData?.text || ""}
        className={bem("refund-cancellation")}
      />
      <Modal
        show={!!modalError}
        onHide={() => {
          setIsBooking(false);
          setModalError(null);
          if (modalError?.type === "Company not verified") {
            navigate("/");
          }
        }}
        title={modalError?.type}
        disableHeader={false}
        disableBackground={false}
        align="bottom"
        BottomControls={BottomControls}
      >
        <div className="text-center p-4 font-inter text-body-lg text-on-background">
          {modalError?.text}
        </div>
      </Modal>
    </>
  );
};
