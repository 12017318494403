export const schedulingBufferMinutes = (impersonate = false) =>
  impersonate ? -10 : 0;

export const amenitiesNameHelper = {
  Non_Toxic: "Non-toxic",
  Waterless: "Waterless",
  Natural_Nails_Only: "Natural Nails Only",
  Handicap_Accessible: "Handicap-accessible",
  Dog_Friendly: "Dog Friendly",
  Kid_Friendly: "Kid Friendly",
  Good_For_Groups: "Good for Groups",
  Private_Parties: "Private Parties",
  TV: "TV",
  Cash_Tips_Only: "Cash Tips Only",
  Free_Wifi: "Free Wifi",
  Masked_Workforce: "Masked Workforce",
  Woman_Owned: "Woman Owned",
  Black_Owned: "Black Owned",
  LGBTQ_Owned: "LGBTQ Owned",
  Asian_Owned: "Asian Owned",
  Veteran_Owned: "Veteran Owned"
};

export const amenitiesWithAlternativeIcons = [
  "Black_Owned",
  "LGBTQ_Owned",
  "Asian_Owned"
];

export const stateOptions = [
  { value: "Alabama", label: "Alabama" },
  { value: "Alaska", label: "Alaska" },
  { value: "Arizona", label: "Arizona" },
  { value: "Arkansas", label: "Arkansas" },
  { value: "California", label: "California" },
  { value: "Colorado", label: "Colorado" },
  { value: "Connecticut", label: "Connecticut" },
  { value: "Delaware", label: "Delaware" },
  { value: "Florida", label: "Florida" },
  { value: "Georgia", label: "Georgia" },
  { value: "Hawaii", label: "Hawaii" },
  { value: "Idaho", label: "Idaho" },
  { value: "Illinois", label: "Illinois" },
  { value: "Indiana", label: "Indiana" },
  { value: "Iowa", label: "Iowa" },
  { value: "Kansas", label: "Kansas" },
  { value: "Kentucky", label: "Kentucky" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Maine", label: "Maine" },
  { value: "Maryland", label: "Maryland" },
  { value: "Massachusetts", label: "Massachusetts" },
  { value: "Michigan", label: "Michigan" },
  { value: "Minnesota", label: "Minnesota" },
  { value: "Mississippi", label: "Mississippi" },
  { value: "Missouri", label: "Missouri" },
  { value: "Montana", label: "Montana" },
  { value: "Nebraska", label: "Nebraska" },
  { value: "Nevada", label: "Nevada" },
  { value: "New Hampshire", label: "New Hampshire" },
  { value: "New Jersey", label: "New Jersey" },
  { value: "New Mexico", label: "New Mexico" },
  { value: "New York", label: "New York" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "North Dakota", label: "North Dakota" },
  { value: "Ohio", label: "Ohio" },
  { value: "Oklahoma", label: "Oklahoma" },
  { value: "Oregon", label: "Oregon" },
  { value: "Pennsylvania", label: "Pennsylvania" },
  { value: "Rhode Island", label: "Rhode Island" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "South Dakota", label: "South Dakota" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
  { value: "Utah", label: "Utah" },
  { value: "Vermont", label: "Vermont" },
  { value: "Virginia", label: "Virginia" },
  { value: "Washington", label: "Washington" },
  { value: "West Virginia", label: "West Virginia" },
  { value: "Wisconsin", label: "Wisconsin" },
  { value: "Wyoming", label: "Wyoming" }
];

export const locationsByState = {
  Alabama: { lat: 32.3792, lng: -86.3077 }, // Montgomery
  Alaska: { lat: 58.3019, lng: -134.4197 }, // Juneau
  Arizona: { lat: 33.4484, lng: -112.074 }, // Phoenix
  Arkansas: { lat: 34.7465, lng: -92.2896 }, // Little Rock
  California: { lat: 37.7749, lng: -122.4194 }, // San Francisco
  Colorado: { lat: 39.7392, lng: -104.9903 }, // Denver
  Connecticut: { lat: 41.7658, lng: -72.6734 }, // Hartford
  Delaware: { lat: 39.1582, lng: -75.5244 }, // Dover
  Florida: { lat: 30.4383, lng: -84.2807 }, // Tallahassee
  Georgia: { lat: 33.749, lng: -84.388 }, // Atlanta
  Hawaii: { lat: 21.3069, lng: -157.8583 }, // Honolulu
  Idaho: { lat: 43.615, lng: -116.2023 }, // Boise
  Illinois: { lat: 39.798, lng: -89.6441 }, // Springfield
  Indiana: { lat: 39.7684, lng: -86.1581 }, // Indianapolis
  Iowa: { lat: 41.5868, lng: -93.625 }, // Des Moines
  Kansas: { lat: 39.0473, lng: -95.6752 }, // Topeka
  Kentucky: { lat: 38.2009, lng: -84.8733 }, // Frankfort
  Louisiana: { lat: 30.4515, lng: -91.1871 }, // Baton Rouge
  Maine: { lat: 44.3106, lng: -69.7795 }, // Augusta
  Maryland: { lat: 38.9784, lng: -76.4922 }, // Annapolis
  Massachusetts: { lat: 42.3601, lng: -71.0589 }, // Boston
  Michigan: { lat: 42.7325, lng: -84.5555 }, // Lansing
  Minnesota: { lat: 44.9537, lng: -93.09 }, // Saint Paul
  Mississippi: { lat: 32.2988, lng: -90.1848 }, // Jackson
  Missouri: { lat: 38.5767, lng: -92.1735 }, // Jefferson City
  Montana: { lat: 46.5891, lng: -112.0391 }, // Helena
  Nebraska: { lat: 40.8136, lng: -96.7026 }, // Lincoln
  Nevada: { lat: 39.1638, lng: -119.7674 }, // Carson City
  NewHampshire: { lat: 43.2073, lng: -71.5371 }, // Concord
  NewJersey: { lat: 40.2171, lng: -74.7429 }, // Trenton
  NewMexico: { lat: 35.687, lng: -105.9378 }, // Santa Fe
  NewYork: { lat: 42.6526, lng: -73.7562 }, // Albany
  NorthCarolina: { lat: 35.7796, lng: -78.6382 }, // Raleigh
  NorthDakota: { lat: 46.8083, lng: -100.7837 }, // Bismarck
  Ohio: { lat: 39.9612, lng: -82.9988 }, // Columbus
  Oklahoma: { lat: 35.4676, lng: -97.5164 }, // Oklahoma City
  Oregon: { lat: 44.9429, lng: -123.0351 }, // Salem
  Pennsylvania: { lat: 40.2732, lng: -76.8867 }, // Harrisburg
  RhodeIsland: { lat: 41.828, lng: -71.4158 }, // Providence
  SouthCarolina: { lat: 34.0007, lng: -81.0348 }, // Columbia
  SouthDakota: { lat: 44.3668, lng: -100.3538 }, // Pierre
  Tennessee: { lat: 36.1627, lng: -86.7816 }, // Nashville
  Texas: { lat: 30.2672, lng: -97.7431 }, // Austin
  Utah: { lat: 40.7608, lng: -111.891 }, // Salt Lake City
  Vermont: { lat: 44.2601, lng: -72.5754 }, // Montpelier
  Virginia: { lat: 37.5407, lng: -77.436 }, // Richmond
  Washington: { lat: 47.0379, lng: -122.9007 }, // Olympia
  WestVirginia: { lat: 38.3498, lng: -81.6326 }, // Charleston
  Wisconsin: { lat: 43.0731, lng: -89.4012 }, // Madison
  Wyoming: { lat: 41.1403, lng: -104.8202 } // Cheyenne
};
